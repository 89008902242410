import Vue from 'vue'
import App from './App.vue'
import router from './router'
import config from '@/config';
import ViewUI from 'view-design';
import store from './store'
import 'view-design/dist/styles/iview.css';
import moment from "moment";

Vue.use(ViewUI);
Vue.use(moment);

Vue.config.productionTip = false
Vue.prototype.config = config
new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
