import request from '@/utils/request'

export function configAdd (data) {
    return request({
        url: '/config/ali_add',
        method: 'post',
        data
    })
}
export function configInfo (data) {
    return request({
        url: '/config/ali_info',
        method: 'post',
        data
    })
}
export function updatePwd (data) {
    return request({
        url: '/updatePwd',
        method: 'post',
        data
    })
}
export function aliEdit (data) {
    return request({
        url: '/config/ali_edit',
        method: 'post',
        data
    })
}
export function aliSmsAdd (data) {
    return request({
        url: '/config/ali_smsadd',
        method: 'post',
        data
    })
}
export function aliSmsInfo (data) {
    return request({
        url: '/config/ali_smsinfo',
        method: 'post',
        data
    })
}
export function aliSsmsEdit (data) {
    return request({
        url: '/config/ali_smsedit',
        method: 'post',
        data
    })
}

export function ormAdd (data) {
    return request({
        url: '/config/orm_add',
        method: 'post',
        data
    })
}

export function ormInfo (data) {
    return request({
        url: '/config/orm_info',
        method: 'post',
        data
    })
}

export function ormEdit (data) {
    return request({
        url: '/config/orm_edit',
        method: 'post',
        data
    })
}

export function operationList (data) {
    return request({
        url: '/operation/list',
        method: 'get',
        params: data
    })
}

export function staffList (data) {
    return request({
        url: '/staff/ls',
        method: 'get',
        params: data
    })
}

export function versionLs (data) {
    return request({
        url: '/version/ls',
        method: 'get',
        params: data
    })
}

export function versionInfo (data) {
    return request({
        url: '/version/info',
        method: 'get',
        params: data
    })
}

export function staffBan (data) {
    return request({
        url: '/staff/is_ban',
        method: 'post',
        data
    })
}

export function versionUp (data) {
    return request({
        url: '/version/update',
        method: 'post',
        data
    })
}

export function configList (data) {
    return request({
        url: '/config/configList',
        method: 'post',
        data
    })
}

export function configOpen (data) {
    return request({
        url: '/config/orm_add',
        method: 'post',
        data
    })
}

export function fileAdd (data) {
    return request({
        url: '/config/fileAdd',
        method: 'post',
        data
    })
}

export function versionDel (data) {
    return request({
        url: '/version/delete',
        method: 'post',
        data
    })
}

export function versionAdd (data) {
    return request({
        url: '/version/add',
        method: 'post',
        data
    })
}

export function staffInfo (data) {
    return request({
        url: '/staff/infos',
        method: 'get',
        params: data
    })
}

export function staffAdd (data) {
    return request({
        url: '/staff/add',
        method: 'post',
        data
    })
}

export function staffEdit (data) {
    return request({
        url: '/staff/edit',
        method: 'post',
        data
    })
}

export function staffDel (data) {
    return request({
        url: '/staff/del',
        method: 'post',
        data
    })
}

export function noticeRead (data) {
    return request({
        url: '/notice/read',
        method: 'post',
        data
    })
}

export function getAnnouncement (data) {
    return request({
        url: '/notice/get_announcement',
        method: 'get',
        params: data
    })
}

export function noticeReadList (data) {
    return request({
        url: '/notice/read_person',
        method: 'get',
        params: data
    })
}

export function staffInfos (data) {
    return request({
        url: '/staff/infos',
        method: 'get',
        params: data
    })
}

export function orm_handle (data) {
    return request({
        url: '/config/orm_handle',
        method: 'POST',
        params: data
    })
}

export function storage_add (data) {
    return request({
        url: '/config/storage_add',
        method: 'POST',
        params: data
    })
}

export function storage_handle (data) {
    return request({
        url: '/config/storage_handle',
        method: 'POST',
        params: data
    })
}

export function sms_handle (data) {
    return request({
        url: '/config/sms_handle',
        method: 'POST',
        params: data
    })
}

export function getStorage (data) {
    return request({
        url: '/config/getStorage',
        method: 'POST',
        params: data
    })
}